<template>
  <div>
    <div id="app">
      <b-card tag="article" style="max-width: 60rem">
        <div align="center">
          <img
            alt="logo"
            src="../assets/img/logo.jpeg"
            width="60%"
            height="60%"
          />
        </div>
        <div align="center" v-cloak>
          <h4>Preencha os campos abaixo</h4>
          <h6>E-mail cadastrado</h6>
          <span>{{ this.email }}</span>
          <br />
          <h6>Digite a sua nova senha</h6>
          <div class="password">
            <input
              id="password"
              :class="{ valid: passwordValidation.valid }"
              :type="passwordVisible ? 'text' : 'password'"
              v-model="password"
              @keyup.enter="focus('checkPassword')"
              autofocus
            />
            <button
              class="visibility"
              tabindex="-1"
              @click="togglePasswordVisibility"
              :arial-label="passwordVisible ? 'Hide password' : 'Show password'"
            >
              <i class="material-icons">{{
                passwordVisible ? "visibility" : "visibility_off"
              }}</i>
            </button>
          </div>
          <h6>Confirme a sua nova senha</h6>
          <div class="password">
            <input
              id="checkPassword"
              :class="{ valid: checkPasswordVisible.valid }"
              :type="checkPasswordVisible ? 'text' : 'password'"
              v-model="checkPassword"
            />
            <button
              class="visibility"
              tabindex="-1"
              @click="toggleCheckPasswordVisibility"
              :arial-label="
                checkPasswordVisible ? 'Hide password' : 'Show password'
              "
            >
              <i class="material-icons">{{
                checkPasswordVisible ? "visibility" : "visibility_off"
              }}</i>
            </button>
          </div>
          <transition name="hint" appear>
            <div
              v-if="passwordValidation.errors.length > 0 && !submitted"
              class="hints"
            >
              <h4>Dicas</h4>
              <p v-for="error in passwordValidation.errors" :key="error">
                {{ error }}
              </p>
            </div>
          </transition>
          <div class="matches" v-if="notSamePasswords">
            <p>Senha não confere.</p>
          </div>
          <button
            class="btn"
            @click="postPassword()"
            v-if="
              this.email != null &&
              passwordsFilled &&
              !notSamePasswords &&
              passwordValidation.valid
            "
          >
            Enviar
          </button>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import 'material-icons/iconfont/material-icons.scss'
import axios from 'axios'
import * as config from '@/config.json'
export default {
  name: 'ResetPassword',
  data: () => {
    return {
      rules: [
        { message: 'Minimo de 8 caracteres.', regex: /.{8,}/ },
        { message: 'Necessário conter uma letra.', regex: /[a-z | A-Z]+/ },
        { message: 'Necessário conter um número.', regex: /[0-9]+/ }
      ],
      password: '',
      checkPassword: '',
      passwordVisible: false,
      checkPasswordVisible: false,
      submitted: false,
      email: null,
      toke: ''
    }
  },

  async beforeMount () {
    this.token = this.$route.query.token
    this.email = this.$route.query.email
  },

  methods: {
    focus (id) {
      document.getElementById(id).focus()
    },

    async postPassword () {
      const url = `${config.default.urlBase}/oac/customer/update-password`

      try {
        const response = await axios({
          url: url,
          method: 'patch',
          headers: { authorization: `${config.default.authorization}` },
          data: {
            email: this.email,
            token: this.token,
            password: this.password
          }
        })

        console.log({ response })
        this.$vToastify.success('Senha Atualizada!', 'Sucesso')

        this.email = ''
        this.password = ''
        this.checkPassword = ''
        this.token = null
      } catch (error) {
        this.$vToastify.error('Senha não Atualizada!', 'Erro')
        this.email = ''
        this.password = ''
        this.checkPassword = ''
        console.error({
          errorMessage: `[PatchPassword] ${error}`
        })
      }
    },

    resetPasswords () {
      this.password = ''
      this.checkPassword = ''
      this.submitted = true
      setTimeout(() => {
        this.submitted = false
      }, 2000)
    },
    togglePasswordVisibility () {
      this.passwordVisible = !this.passwordVisible
    },
    toggleCheckPasswordVisibility () {
      this.checkPasswordVisible = !this.checkPasswordVisible
    }
  },
  computed: {
    notSamePasswords () {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword
      } else {
        return false
      }
    },
    passwordsFilled () {
      return this.password !== '' && this.checkPassword !== ''
    },
    passwordValidation () {
      const errors = []
      for (const condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message)
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors }
      } else {
        return { valid: false, errors }
      }
    }
  }
}
</script>

<style src="../assets/sass/ResetPassword.scss" scoped lang="scss"/>
